
import { defineComponent, reactive, toRefs, computed, watch } from "vue";
import { getColumnOneList, getModelList, getModelInfo } from "@/api/index.js";
import { ElMessage } from "element-plus";
import Sidebar from "@/components/Sidebar/index.vue";
import Details from "@/components/Details/index.vue";
import triangleIcon from "assets/images/triangle_icon.png";
import { useRoute, useRouter } from "vue-router";
export default defineComponent({
  setup() {
    interface props {
      type?: any;
      articleId?: any;
      menuList?: Array<any>;
      list?: Array<any>;
      keyword?: string;
      total?: any;
      page?: any;
      limit?: any;
      isShow?: boolean;
      currentItem: { title: any; content: any };
      loading?: boolean;
    }
    let state: props = reactive({
      type: "0",
      menuList: [],
      list: [],
      keyword: "",
      total: 0,
      page: 1,
      limit: 10,
      isShow: false,
      currentItem: { title: "", content: "" },
      loading: true,
    });

    // 获取咨询专家栏目
    getColumnOneList({ pid: 21 }).then((res: any) => {
      if (res.code == "0") {
        state.menuList = [...res.data];
      } else {
        ElMessage.error(res.msg);
      }
    });

    const activeIndex = computed(() => {
      let index = 0;
      state.menuList?.map((r, i) => {
        if (r.type == state.type) {
          index = i;
        }
      });
      return index;
    });

    const $route = useRoute();
    const $router = useRouter();
    watch(
      () => $route.query,
      (newQuery, oldQuery) => {
        if (newQuery?.type !== oldQuery?.type) {
          if ($route.query.type) {
            state.type = $route.query.type;
          }
        }
      },
      { immediate: true }
    );

    const sidebarclick = (item: any) => {
      state.type = item.type;
      $router.push({ query: ($route.query, { type: item.type }) });
      state.isShow = false;
      state.keyword = "";
    };

    const modelListFn = async (type: any) => {
      const params = {
        type,
        keyword: state.keyword,
        page: state.page,
        limit: state.limit,
      };
      return await getModelList(params).then((res: any) => {
        if (res.code == "0") {
          return res;
        }
      });
    };

    const init = () => {
      modelListFn(state.type).then((res) => {
        state.list = res.data || [];
        state.total = Number(res.count);
      });
    };

    const currentChange = (page: any) => {
      state.page = page;
      init();
    };

    init(); // 获取数据

    watch(
      () => state.type,
      (type, prevType) => {
        if (type != prevType) {
          init();
        }
      }
    );

    const ModelInfo = (id: any) => {
      getModelInfo({ id }).then((res: any) => {
        state.currentItem = res.data;
        state.loading = false;
      });
    };

    const openDetails = (item: any) => {
      // state.isShow = true;
      // state.loading = true;
      // state.currentItem = { title: "", content: "" };
      // ModelInfo(item.id);
      const to = $router.resolve({
        path: $route.path,
        query: ($route.query, { type: state.type, articleId: item.id }),
      });
      window.open(to.href, "_blank");
    };

    watch(
      () => $route.query,
      (newQuery, oldQuery) => {
        if (newQuery?.type !== oldQuery?.type) {
          if ($route.query.type) {
            state.type = $route.query.type;
          }
          if ($route.query.articleId) {
            state.articleId = $route.query.articleId;
            state.isShow = true;
            ModelInfo(state.articleId);
            state.loading = true;
            // openDetails({ id: state.articleId });
          }
        }
      },
      { immediate: true }
    );

    return {
      ...toRefs(state),
      triangleIcon,
      activeIndex,
      sidebarclick,
      init,
      currentChange,
      openDetails,
    };
  },
  components: { Sidebar, Details },
});
